import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "./contact.module.scss"

function PageContact() {

  const PageContactData = useStaticQuery(graphql`
    {
      allWordpressPage(filter: {wordpress_id: {eq: 448}}) {
        edges {
          node {
            acf {
              image {
                source_url
              }
              content
            }
          }
        }
      }
    }
  `)

  console.log(PageContactData)
  const ACFpath = PageContactData.allWordpressPage.edges[0].node.acf
  const Content = ACFpath.content
  const ImageUrl = ACFpath.image.source_url

  return (
    <Layout>
      <SEO title="Contact" />
      <section className={'paddingSection ' + styles.sectionContact}>
        <div className={'flex center bottom ' + styles.wrapper}>
          <div className={'col col-6 col-m-8'}>
            <img src={ImageUrl}></img>
          </div>
          <div className={'col col-5 col-m-8'}>
            <h4>CONTACT</h4>
            <br></br>
            <div dangerouslySetInnerHTML={{ __html: Content }}></div>
          </div>
        </div>
      </section>

    </Layout>
  )
}
export default PageContact
